import React from 'react';
import './App.css';
import Maintenance from './Maintenance'; // Importa el componente

function App() {
  return (
    <div className="App">
      <Maintenance />
    </div>
  );
}

export default App;
