import React, { useState } from 'react';
import './Maintenance.css';
import flowerImage from './purple-flower.png'; // Asegúrate de que la imagen está en la carpeta src


const Maintenance = () => {
  const [guess, setGuess] = useState('');
  const [message, setMessage] = useState('');
  const [attempts, setAttempts] = useState(5);
  const [randomNumber, setRandomNumber] = useState(() => Math.floor(Math.random() * 100) + 1);
  const [gameOver, setGameOver] = useState(false);

  const handleGuess = (event) => {
    event.preventDefault();

    if (gameOver) return;

    const userGuess = parseInt(guess, 10);
    if (isNaN(userGuess)) {
      setMessage('Por favor, ingresa un número válido.');
      return;
    }

    if (userGuess === randomNumber) {
      setMessage(`¡Correcto! Has adivinado el número en ${6 - attempts} intentos.`);
      setGameOver(true);
    } else if (userGuess < randomNumber) {
      setMessage('Demasiado bajo. Intenta de nuevo.');
      setAttempts(attempts - 1);
    } else {
      setMessage('Demasiado alto. Intenta de nuevo.');
      setAttempts(attempts - 1);
    }

    if (attempts <= 1 && userGuess !== randomNumber) {
      setMessage(`Lo siento, has utilizado todos tus intentos. El número era ${randomNumber}.`);
      setGameOver(true);
    }

    setGuess('');
  };

  const resetGame = () => {
    setGuess('');
    setMessage('');
    setAttempts(5);
    setRandomNumber(Math.floor(Math.random() * 100) + 1);
    setGameOver(false);
  };

   // Crear un arreglo de flores para el fondo
//    const createFlowers = () => {
//     const flowers = [];
//     const flowerCount = 20;
//     for (let i = 0; i < flowerCount; i++) {
//       // Calcula posiciones aleatorias para cada flor
//       const angle = Math.random() * Math.PI * 2; // Ángulo aleatorio para la posición de la flor
//       const radius = Math.random() * 0.3 + 0.4; // Radio aleatorio para alejar flores del centro
//       const top = Math.sin(angle) * radius * 50 + 50;
//       const left = Math.cos(angle) * radius * 50 + 50;
      
//       flowers.push(
//         <div
//           key={`flower-${i}`}
//           className="flower"
//           style={{
//             backgroundImage: `url(${flowerImage})`,
//             top: `${top}%`,
//             left: `${left}%`
//           }}
//         ></div>
//       );
//     }
//     return flowers;
//   };
  
const createFlowers = () => {
    const positions = [
        // Primera fila
        { top: '5%', left: '5%' }, { top: '5%', left: '20%' }, { top: '5%', left: '40%' },
        { top: '5%', left: '60%' }, { top: '5%', left: '80%' }, { top: '5%', left: '95%' },
        // Segunda fila
        { top: '20%', left: '10%' }, { top: '20%', left: '30%' }, { top: '20%', left: '50%' },
        { top: '20%', left: '70%' }, { top: '20%', left: '90%' },
        // Tercera fila
        { top: '40%', left: '5%' }, { top: '40%', left: '25%' }, { top: '40%', left: '45%' },
        { top: '40%', left: '65%' }, { top: '40%', left: '85%' }, { top: '40%', left: '95%' },
        // Cuarta fila
        { top: '60%', left: '10%' }, { top: '60%', left: '30%' }, { top: '60%', left: '50%' },
        { top: '60%', left: '70%' }, { top: '60%', left: '90%' },
        // Quinta fila
        { top: '80%', left: '5%' }, { top: '80%', left: '20%' }, { top: '80%', left: '40%' },
        { top: '80%', left: '60%' }, { top: '80%', left: '80%' }, { top: '80%', left: '95%' },
        // Sexta fila
        { top: '95%', left: '10%' }, { top: '95%', left: '30%' }, { top: '95%', left: '50%' },
        { top: '95%', left: '70%' }, { top: '95%', left: '90%' },
      ];
  
    return positions.map((pos, i) => (
      <div
        key={`flower-${i}`}
        className="flower"
        style={{
          backgroundImage: `url(${flowerImage})`,
          top: pos.top,
          left: pos.left,
        }}
      ></div>
    ));
  };
  

  return (
    <div className="maintenance-container">
        
        {createFlowers()}
        <div className="main-content"> {/* Contenedor para el área principal */}
            <h1>MERY ANDÍA</h1>
            <h3>Desarrolladora Web</h3>
            <h2>✨En Mantenimiento✨</h2>
            <p>Estoy trabajando para re-lanzar mi portfolio.</p>
            <div className="game">
                <h4>¡No te vayas sin jugar!</h4>
                <p>Intenta adivinar el número que estoy pensando (entre 1 y 100). {!gameOver ? `Tienes ${attempts} intentos.` : ''}</p>
                <form onSubmit={handleGuess}>
                <input
                    type="number"
                    value={guess}
                    onChange={(e) => setGuess(e.target.value)}
                    placeholder="<!-- ¡Intenta adivinar! -->"
                    min="1"
                    max="100"
                    disabled={gameOver}
                />
                <button type="submit" disabled={gameOver}>Adivinar</button>
                </form>
                {message && <p>{message}</p>}
                {gameOver && <button onClick={resetGame}>Jugar de nuevo</button>}
            </div>
        </div>
    </div>
  );
};

export default Maintenance;
